import FAQ from '../components/faq';
import Footer from '../components/footer';
import Hero from '../components/hero';
import Kontak from '../components/kontak';
import Mentor from '../components/mentor';
import NavBar from '../components/navbar';
import Society5Program from '../components/program';
import TentangKami from '../components/tentang-kami';

export default function Home() {
  return (
    <>
      <NavBar />
      <Hero />
      <Mentor />
      <Society5Program />
      <TentangKami />
      <FAQ />
      <Kontak />
      <Footer />
    </>
  );
}
