import { useState } from 'react';

export default function FAQ() {
    const faqData = [
        {
            question: "Apa itu Society 5?",
            answer: "Society 5 adalah organisasi yang berfokus pada bimbingan pendidikan untuk studi di luar negeri. Kami menyediakan panduan aplikasi universitas, bimbingan beasiswa, pelatihan IELTS, dan keterampilan Bahasa Inggris, serta mentoring karir."
        },
        {
            question: "Bagaimana Society 5 dapat membantu saya dalam mendaftar ke universitas di luar negeri?",
            answer: "Kami menyediakan panduan lengkap mulai dari persiapan dokumen, penulisan esai, hingga latihan wawancara, untuk meningkatkan peluang Anda diterima di universitas impian Anda."
        },
        {
            question: "Bagaimana cara mendaftar program di Society 5?",
            answer: "Anda dapat mendaftar program kami melalui formulir aplikasi di website kami. Tim kami akan menghubungi Anda untuk informasi lebih lanjut."
        },
        {
            question: "Apa saja persyaratan untuk mengikuti program Society 5?",
            answer: "Persyaratan umum meliputi motivasi yang kuat untuk studi di luar negeri, kemampuan Bahasa Inggris dasar, dan komitmen untuk mengikuti seluruh proses bimbingan."
        },
        {
            question: "Berapa biaya layanan di Society 5?",
            answer: "Society 5 tidak mengenakan biaya kepada para mahasiswa yang ikut serta pada program bimbingan kami."
        },
        {
            question: "Bagaimana Society 5 membantu dalam persiapan karir saya?",
            answer: "Kami menyediakan mentoring karir yang mencakup konsultasi karir dan mentoring akademik dalam pembuatan paper dan jurnal international."
        },
    ];

    return (
        <div className="lg:px-36 px-8 py-12 bg-emerald-600 text-white text-center">
            <h2 className="text-3xl font-bold pb-8">
                Frequently Asked Question
            </h2>
            <div className="flex flex-col items-center gap-4 text-black px-4 lg:px-48 text-left">
                {faqData.map((faq, index) => (
                    <FAQItem key={index} question={faq.question} answer={faq.answer} />
                ))}            </div>
        </div>
    );
}

const FAQItem = ({
    question,
    answer
}: {
    question: string;
    answer: string;
}) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className={`w-full bg-white rounded-lg overflow-hidden`}>
            <div
                className={`flex justify-between items-center cursor-pointer px-4 py-3`}
                onClick={() => setIsOpen(!isOpen)}
            >
                <p className="font-semibold lg:text-lg">{question}</p>
                <div
                    className={`transform transition-transform  ${isOpen ? 'rotate-180' : ''}`}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                    </svg>
                </div>
            </div>
            {isOpen && (
                <div className="px-4 py-2 text-left">
                    <p className="text-gray-600">{answer}</p>
                </div>
            )}
        </div>
    );
};


