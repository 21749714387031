import { Box, Image } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

export default function Logo(props: any) {
  return (
    <Box {...props}>
      <Link to='/'>
        <Image src='/assets/logo.png' alt='Society 5 Logo' />
      </Link>
    </Box>
  );
}
