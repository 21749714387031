import { Flex, Image, Text, VStack } from '@chakra-ui/react';

export default function Mentor() {
  return (
    <VStack py={[16]} id='mentor' textAlign='center' px={8} gap={0}>
      <Text
        as='h2'
        fontWeight='bold'
        fontSize={['large', 'x-large', 'x-large']}
      >
        Society 5 Membantu Mahasiswa Memperoleh Beasiswa
      </Text>
      <Text
        as='h2'
        fontWeight='bold'
        fontSize={['x-large', 'x-large', 'xx-large']}
        color={'green.500'}
        py={1}
      >
        di Universitas Top Eropa
      </Text>
      <Flex flexDir={['column', 'column', 'row']} pt={[12]} gap={[12, 12, 16]}>
        <Image src='assets/mentor-1.png' />
        <Image src='assets/mentor-2.png' />
      </Flex>
    </VStack>
  );
}
