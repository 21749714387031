import { EmailIcon, PhoneIcon } from '@chakra-ui/icons';
import { Button, SimpleGrid, Text, Wrap } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import Logo from './logo';

export default function Footer() {
  return (
    <SimpleGrid
      columns={[1, 1, 2, 4]}
      bg='green.50'
      px={[8, 16]}
      py={[16]}
      mt={[16]}
      gap={8}
    >
      <Wrap direction={'column'} spacing={2}>
        <Logo />
      </Wrap>

      <Wrap direction={'column'} spacing={2}>
        <Text fontWeight={'bold'} as={'h3'} fontSize='large'>
          Hubungi Kami
        </Text>
        <Wrap align={'center'} spacing={4}>
          <EmailIcon color='green' fontSize='large' />
          <Text>info@society5.id</Text>
        </Wrap>
        <Wrap align={'center'} spacing={4}>
          <PhoneIcon color='green' fontSize='large' />
          <Text>(+62) 821 1437 4262</Text>
        </Wrap>
      </Wrap>

      <Wrap direction={'column'} spacing={2}>
        <Text fontWeight={'bold'} as={'h3'} fontSize='large'>
          Tentang Society 5
        </Text>
        <Link to='#tentang-kami'>
          <Text>Tentang Kami</Text>
        </Link>
        <Link to='#program'>
          <Text>Program Kami</Text>
        </Link>
      </Wrap>

      <SubscribeForm />
    </SimpleGrid>
  );
}

function SubscribeForm() {
  return (
    <Wrap spacing={2} direction={'column'}>
      <Text fontWeight={'bold'} as={'h3'} fontSize='large'>
        Subscribe
      </Text>
      <Text>Dapatkan informasi terbaru dari kami</Text>

      <Button colorScheme={'green'}>
        <Link to='http://eepurl.com/iHsoQM' target='_blank'>
          Daftar Sekarang
        </Link>
      </Button>
    </Wrap>
  );
}
