import { EmailIcon, PhoneIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Input,
  Stack,
  StackDivider,
  Text,
  Textarea,
  VStack,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { FaWhatsapp } from 'react-icons/fa';

export default function Kontak() {
  return (
    <VStack py={[12]} px={[8, 8, 36]} id='kontak' gap={[8, 8, 16]}>
      <Text
        as='h2'
        fontWeight='bold'
        fontSize={['x-large', 'x-large', 'xx-large']}
      >
        Hubungi Kami
      </Text>

      <Stack
        direction={['column', 'column', 'column', 'row']}
        w='100%'
        gap={16}
      >
        <KontakForm />

        <VStack
          py={8}
          px={[8, 8, 16]}
          gap={4}
          divider={<StackDivider borderColor='gray.200' />}
          bg='#e7f8ee'
          borderRadius='3xl'
          align={['center', 'center', 'flex-start']}
          justifyContent='space-evenly'
        >
          <KontakIconInfo
            icon={<FaWhatsapp fontSize='xx-large' />}
            title='Whatsapp'
            subtitle='(+62) 821 1437 4262'
          />
          <KontakIconInfo
            icon={<PhoneIcon fontSize='xx-large' />}
            title='No Telfon'
            subtitle='(+62) 821 1437 4262'
          />
          <KontakIconInfo
            icon={<EmailIcon fontSize='xx-large' />}
            title='Email'
            subtitle='info@society5.id'
          />
        </VStack>
      </Stack>
    </VStack>
  );
}

function KontakForm() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const toast = useToast();

  const onSubmit = async (data: any) => {
    try {
      await axios.post('https://formspree.io/f/moqowwyp', data);

      toast({
        title: 'Pesan Terkirim',
        description: 'Pesan berhasil terkirim!',
        status: 'success',
        position: 'top',
        duration: 5000,
        isClosable: true,
      });
      reset();
    } catch (error) {
      toast({
        title: 'Error',
        description:
          'Terjadi sebuah kesalahan, mohon dicoba beberapa saat lagi.',
        status: 'error',
        position: 'top',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <VStack
      p={[6, 6, 8]}
      gap={4}
      flex={1}
      borderRadius={['xl', 'xl', '3xl']}
      alignItems='flex-start'
      justifyContent='space-between'
      outline='1px solid rgba(48, 146, 85, 0.2);'
      as='form'
      onSubmit={handleSubmit(onSubmit)}
    >
      <Text fontWeight='bold' fontSize={['large', 'xx-large']}>
        Kirim Pesan
      </Text>
      <FormControl isInvalid={errors.name !== undefined}>
        <Input
          id='name'
          size={['md', 'lg']}
          placeholder='Nama'
          {...register('name', { required: 'Name is required' })}
        />
        <FormErrorMessage>
          {errors.name && errors.name.message?.toString()}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={errors.email !== undefined}>
        <Input
          size={['md', 'lg']}
          placeholder='Email'
          {...register('email', {
            required: 'Email is required',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: 'Invalid email address',
            },
          })}
        />
        <FormErrorMessage>
          {errors.email && errors.email.message?.toString()}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={errors.subject !== undefined}>
        <Input
          size={['md', 'lg']}
          placeholder='Judul Pesan'
          {...register('subject', { required: 'Subject is required' })}
        />
        <FormErrorMessage>
          {errors.subject && errors.subject.message?.toString()}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={errors.message !== undefined}>
        <Textarea
          size={['md', 'lg']}
          placeholder='Isi Pesan'
          {...register('message', { required: 'Message is required' })}
        />
        <FormErrorMessage>
          {errors.message && errors.message.message?.toString()}
        </FormErrorMessage>
      </FormControl>

      <Button w='100%' colorScheme='green' type='submit'>
        Kirim
      </Button>
    </VStack>
  );
}

function KontakIconInfo({
  icon,
  title,
  subtitle,
}: {
  icon: any;
  title: string;
  subtitle: string;
}) {
  return (
    <Stack
      direction={['column', 'column', 'row']}
      alignItems={'center'}
      gap={[4, 4, 8]}
    >
      <Box
        p={8}
        bg='white'
        color='green.500'
        borderRadius='50%'
        transitionDuration={'0.3s'}
        w='fit-content'
        _hover={{
          bg: 'green.500',
          color: 'white',
        }}
      >
        {icon}
      </Box>

      <VStack alignItems={['center', 'center', 'flex-start']}>
        <Text fontSize={['large']} color='green'>
          {title}
        </Text>
        <Text fontWeight='bold' fontSize={['large', 'x-large']}>
          {subtitle}
        </Text>
      </VStack>
    </Stack>
  );
}
