import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Show,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';

export default function Society5Program() {
  return (
    <>
      <Image src={'assets/divider.png'} w={'100%'} />
      <VStack py={[8, 8, 12]} px={8} spacing={8} bg='#D1F4DD' id='program'>
        <Text
          as='h2'
          fontWeight='bold'
          fontSize={['x-large', 'x-large', 'xx-large']}
        >
          Program Society 5
        </Text>

        <HStack alignItems='flex-start'>
          <Show above='xl'>
            <VStack top='135px' position='relative' mr={[8]} spacing={0}>
              <NumberCard number={1} />
              <NumberDivider />
              <NumberCard number={2} />
              <NumberDivider />
              <NumberCard number={3} />
              <NumberDivider />
              <NumberCard number={4} />

            </VStack>
          </Show>

          <VStack spacing={[4, 6, 12]}>
            <ProgramCard
              title='Bimbingan Pendaftaran Universitas'
              description='Raih kesempatan studi di universitas impian anda melalui panduan pendaftaran yang telah disusun oleh mentor berpengalaman.'
              image='assets/program-thumbnail-1.jpg'
            />

            <ProgramCard
              title='Bimbingan Langsung untuk Mendapatkan Beasiswa'
              description='Dapatkan bimbingan langsung untuk menavigasi dunia beasiswa dan mendapatkan pendanaan untuk studi kamu.'
              image='assets/program-thumbnail-2.jpg'
            // buttonLabel='Daftar Program Mentoring'
            // buttonLink='https://forms.gle/iLgJ4pByzDvFpMAn6'
            />

            <ProgramCard
              title='Pelatihan Bahasa Inggris dan Persiapan Tes TOEFL/IELTS'
              description='Pelatihan workshop khusus untuk meningkatkan kesempatan meraih beasiswa melalui kompetisi, kesempatan penelitian, pelatihan bahasa, dan penulisan motivation letter'
              image='assets/program-thumbnail-3.jpg'
            />

            <ProgramCard
              title='Mentoring Karir dan Pengembangan Diri'
              description='Bentuk masa depan karir kamu dengan bimbingan profesional untuk mempersiapkan diri di dunia kerja dan akademik.'
              image='assets/program-thumbnail-4.jpg'
            />

          </VStack>
        </HStack>


        <WebinarBanner />

      </VStack>
      <Image src={'assets/divider.png'} w={'100%'} transform='scale(1, -1);' />
    </>
  );
}

function WebinarBanner() {
  return (
    <div className="lg:w-3/4 py-4">
      <div
        className="lg:bg-contain bg-cover bg-center bg-no-repeat px-8 lg:px-24 py-8 lg:py-12 rounded-xl"
        style={{
          backgroundImage: `url('/assets/rectangle-rounded.png')`,
        }}
      >
        <div className="flex flex-col lg:flex-row items-center justify-between gap-8 text-center lg:text-left">
          <div className="flex-1 w-full px-2">
            <img
              className="object-cover w-full"
              src="assets/3-uni.png"
              alt="Universities"
            />
          </div>
          <div className="flex-1 w-full px-2 space-y-4 text-white">
            <p className="font-bold text-lg">
              Ikuti Webinar Gratis dari Society 5
            </p>
            <p className="text-base lg:text-lg">
              Membahas informasi seputar studi di luar negeri, pendaftaran, dan beasiswa!
            </p>
            <button className="bg-white text-black py-2 px-4 rounded hover:bg-gray-100 transition-colors">
              <Link to="https://forms.gle/ge7YXZKqRamF2gVJ9" target='_blank'>
                Daftar Sekarang
              </Link>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

function ProgramCard({
  title,
  description,
  image,
  buttonLabel,
  buttonLink,
}: {
  title: string;
  description: string;
  image: string;
  buttonLabel?: string;
  buttonLink?: string;
}) {
  return (
    <Flex
      w={['100%', '100%', 600, 800]}
      px={[4, 4, 8]}
      py={[4, 4, 6]}
      bg='white'
      flexDir={['column-reverse', 'column-reverse', 'row']}
      borderRadius='lg'
      boxShadow='base'
      alignItems={["center"]}
      textAlign={["center", "left"]}
    >
      <VStack pr={[0, 0, 8]} alignItems='start' gap={4} pt={[4, 4, 0]}>
        <Text fontWeight='bold' fontSize={['large', 'large', 'x-large']}>
          {title}
        </Text>
        <Text fontSize={['base', 'base', 'large']}>{description}</Text>

        {buttonLabel && buttonLink && (
          <Button colorScheme='blue'>
            <Link to={buttonLink} target='_blank'>
              {buttonLabel}
            </Link>
          </Button>
        )}
      </VStack>
      <Image aspectRatio='initial' objectFit='contain' flex={1} maxW={200} src={image} />
    </Flex>
  );
}

function NumberDivider() {
  return (
    <hr
      aria-orientation='vertical'
      style={{
        borderLeft: '4px dashed #008000',
        height: '258px',
        width: '0px',
        borderRight: 'none',
      }}
    />
  );
}

function NumberCard({ number }: { number: number }) {
  return (
    <Box
      bg='white'
      w='50px'
      h='50px'
      borderRadius='50%'
      display='flex'
      alignItems='center'
      textAlign='center'
      boxShadow='base'
    >
      <Text color='green' w='100%' fontWeight='bold' fontSize='large'>
        {number}
      </Text>
    </Box>
  );
}
