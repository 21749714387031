import { Box, Button, Image, Show, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

export default function Hero() {
  return (
    <Box
      w='100%'
      minH='60vh'
      bgImg={['', '', '/assets/hero-desktop.jpg']}
      bgPos={'right'}
      bgColor={['green.500', 'green.500', 'transparent']}
      bgSize={'cover'}
      maxWidth={'100%'}
      pt={[0, 0, 16]}
      pb={[8, 8, 16]}
      display={['', 'flex', 'flex']}
      alignItems={'center'}
    >
      <Show below='md'>
        <Image src='/assets/hero-mobile.png' w='100%' pb={8} />
      </Show>
      <Box
        pl={[8, 8, 32]}
        pr={[8, 8, 0]}
        maxW={['', 'lg', 'xl']}
        bg={'transparent'}
        color='white'
        textAlign={['center', 'center', 'left']}
      >
        <Text fontSize={['2xl', '2xl', '4xl']} fontWeight='bold'>
          Capai Impian Studi di Luar Negeri dengan Society 5
        </Text>
        <Text fontSize={['md', 'md', 'lg']} fontWeight={''} py={4}>
          Kami siap bantu persiapan pendaftaran kuliah di kampus impian kamu.
        </Text>
        <Button size={['sm', 'sm', 'md']}>
          <Link
            to='https://api.whatsapp.com/send?phone=6282114374262&text=Halo+Min%2C+aku+tertarik+untuk+tahu+lebih+lanjut+mengenai+layanan+Society+5'
            target='_blank'
          >
            Hubungi Kami
          </Link>
        </Button>
      </Box>
    </Box>
  );
}
