import { Text, VStack } from '@chakra-ui/react';

export default function TentangKami() {
  return (
    <VStack py={[12]} px={[8, 24, 36]} id='tentang-kami' gap={8}>
      <Text
        as='h2'
        fontWeight='bold'
        fontSize={['x-large', 'x-large', 'xx-large']}
      >
        Tentang Kami
      </Text>

      <Text as='p' fontSize={['large', 'large', 'x-large']} textAlign='center'>
        Misi kami adalah membantu mahasiswa di seluruh dunia mencapai masa depan
        yang lebih baik melalui Program Society 5. Kami berkomitmen mendukung,
        mendidik dan mengembangkan mereka dalam melanjutkan studi magister di
        universitas di Finlandia/Eropa, menggabungkan pekerjaan dengan
        pembelajaran, mendorong pendirian perusahaan startup, dan mencapai
        financial freedom di usia 35 tahun.
      </Text>
    </VStack>
  );
}
